import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"ExpansionComponent ExpansionBordered mt-6"},[_c(VExpansionPanel,{attrs:{"disabled":_vm.loadingPanel},on:{"change":function($event){return _vm.$emit('panel-event')}}},[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle",attrs:{"expand-icon":""},on:{"click":function($event){return _vm.changeStateExpansive()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"iconExpand"},[_vm._v(" "+_vm._s(_vm.iconExpansive)+" ")])]},proxy:true}])},[_vm._v(" Notes "),_c('div',{staticClass:"ExpansionState HideOnMovil"},[_vm._v(" "+_vm._s(_vm.stateExpansiveMessage)+" ")])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent"},[_c('NotesCreated'),_vm._l((_vm.newNoteArray),function(item,index){return _c('NoteTextEditor',{key:index,on:{"NoteValue":function($event){return _vm.GetNoteValue($event)}}})}),_c('div',{staticClass:"CreateNoteCont d-flex justify-start align-center"},[_c(VBtn,{staticClass:"btn",attrs:{"text":"","rounded":""},on:{"click":function($event){return _vm.addNote(_vm.newNoteArray.length)}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_vm._v(" Add New Note ")],1)],1)],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }